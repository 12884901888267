import { useState, useEffect } from "react";
import { usePessoa } from "../Pessoa/PessoaContext";
import { statusAgendamento } from "../Agendamento/AgendamentoContext";
import { Atendimento } from "../../api/api";
import { useAuth } from "../Auth/AuthContext";

export const useHistoricoAtendimento = () => {
  const { pessoa } = usePessoa();
  const [isLoading, setIsLoading] = useState(false);
  const [historico, setHistorico] = useState([]);
  const { user } = useAuth();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const ITEMS_PER_PAGE = 10;

  const load = async (currentPage = 1, append = false) => {
    setIsLoading(true);
    const { data } = await Atendimento(user.jwt).get({
      pessoa: pessoa.id,
      status: statusAgendamento.realizado,
      _sort: "dataAtendimento:desc",
      _start: (currentPage - 1) * ITEMS_PER_PAGE,
      _limit: ITEMS_PER_PAGE,
    });

    if (data.length < ITEMS_PER_PAGE) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }

    if (append) {
      setHistorico((prev) => [...prev, ...data]);
    } else {
      setHistorico(data);
    }

    setIsLoading(false);
  };

  const loadMore = async () => {
    if (!hasMore || isLoading) return false;

    const nextPage = page + 1;
    setPage(nextPage);
    await load(nextPage, true);

    return hasMore;
  };

  useEffect(() => {
    if (pessoa.id) {
      setPage(1);
      setHasMore(true);
      load(1, false);
      return;
    }

    setHistorico([]);
    setIsLoading(false);
    setHasMore(false);
  }, [pessoa.id]);

  return { historico, isLoading, loadMore, hasMore };
};
