import { useState, useEffect } from "react";
import { createContext, useContext } from "react";
import { login } from "../../api/api";
import { useMessage } from "../Message/MessageContext";
import localStorage from "../../utils/localStorage";

export const AuthContext = createContext({});

export function useAuth() {
  return useContext(AuthContext);
}

const { getSession, getStorage, setSession, setStorage } = localStorage("CEC");

export function AuthProvider({ children }) {
  const [user, setUser] = useState(getSession() || getStorage());
  const [remember, setRemember] = useState(user?.remember);
  const [isLoading, setIsLoading] = useState(false);
  const { setSuccess, setError } = useMessage();

  useEffect(() => {
    if (user === false) {
      setStorage();
      return setSession();
    }

    if (remember) {
      return setStorage(user);
    }

    setSession(user);
  }, [user]);

  const value = {
    authenticated: !!user,
    isLoading,
    user,
    remember,
    login: async (identifier, password, save) => {
      setIsLoading(true);
      try {
        const {
          data: { user, jwt },
        } = await login(identifier, password);

        setRemember(save);
        setUser({ user, jwt, remember: save });

        setSuccess(`Bem vindo ${user.username}!`);
      } catch (e) {
        setError("Usuário ou senha inválidos");
      }
      setIsLoading(false);
    },
    logout: () => setUser(false),
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
