import React, { useState, useEffect } from "react";
import clsx from "clsx";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { AgendamentoProvider } from "../Agendamento/AgendamentoContext";
import { PessoaAgendamentoProvider } from "../PessoaAgendamento/PessoaAgendamentoContext";
import { PessoaAgendamentoAnexosProvider } from "../PessoaAgendamento/PessoaAgendamentoAnexosContext";
import { PessoaProvider } from "../Pessoa/PessoaContext";
import { PessoaDocumentoProvider } from "../PessoaDocumento/PessoaDocumentoContext";
import { PessoaBuscaDialog } from "./PessoaBuscaDialog";
import { PessoaAgenda } from "../PessoaAgenda/PessoaAgenda";

import { Menu } from "./Menu";
import StyledRoot, { classes } from "./styles";
import { DesligamentoProvider } from "../PessoaDesligamento/DesligamentoContext";
import { HeaderPrint } from "./HeaderPrint";
import { HistoricoAtendimento } from "../PessoaAgenda/HistoricoAtendimento";

export default function Layout({ children }) {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));

  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(!isMedium);
  }, [isMedium]);

  return (
    <AgendamentoProvider>
      <PessoaDocumentoProvider>
        <PessoaProvider>
          <PessoaAgendamentoAnexosProvider>
            <PessoaAgendamentoProvider>
              <DesligamentoProvider>
                <HeaderPrint />
                <StyledRoot className={classes.container}>
                  <CssBaseline />
                  <Drawer
                    anchor="left"
                    sx={{ height: "100vh" }}
                    variant={"permanent"}
                    classes={{
                      paper: clsx(
                        classes.drawerPaper,
                        !open && classes.drawerPaperClose
                      ),
                    }}
                    open={open}
                  >
                    <Menu toggleDrawer={toggleDrawer} open={open} />
                  </Drawer>
                  <Box component="main" className={classes.body}>
                    <PessoaBuscaDialog />
                    <Grid container spacing={3} style={{ marginTop: 20 }}>
                      <Container component="main" maxWidth="md">
                        {children}
                      </Container>
                    </Grid>
                  </Box>
                  <PessoaAgenda />
                  <HistoricoAtendimento />
                </StyledRoot>
              </DesligamentoProvider>
            </PessoaAgendamentoProvider>
          </PessoaAgendamentoAnexosProvider>
        </PessoaProvider>
      </PessoaDocumentoProvider>
    </AgendamentoProvider>
  );
}
