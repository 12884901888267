import { styled } from "@mui/material/styles";

const PREFIX = "Page";

export const classes = {
  paper: `${PREFIX}-paper`,
  avatar: `${PREFIX}-avatar`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`,
  newContainer: `${PREFIX}-newContainer`,
  bottomLoading: `${PREFIX}-bottomLoading`,
  accordion: `${PREFIX}-accordion`,
  chip: `${PREFIX}-chip`,
};

export default styled("div")(({ theme: { spacing, palette } }) => ({
  [`&.${classes.paper}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  [`& .${classes.avatar}`]: {
    margin: spacing(1),
    backgroundColor: palette.secondary.main,
  },
  [`& .${classes.form}`]: {
    width: "100%", // Fix IE 11 issue.
    marginTop: spacing(3),
  },
  [`& .${classes.submit}`]: {
    margin: spacing(3, 0, 2),
  },
  [`& .${classes.newContainer}`]: {
    textAlign: "right",
  },
  [`& .${classes.bottomLoading}`]: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
  },
  [`& .${classes.accordion}`]: {
    display: "block",
  },
  [`& .${classes.chip}`]: {
    width: 16,
    height: 16,
    borderRadius: 4,
    marginRight: spacing(0.5),
    display: "inline-block",
  },

  "& #mui-rte-editor": {
    minHeight: "130px",
  },

  "@media print": {
    "& .MuiOutlinedInput-input, & .MuiOutlinedInput-root, & .MuiAutocomplete-input":
      {
        paddingLeft: "0 !important",
      },
    "& .MuiInputLabel-root": {
      left: -16,
    },
    "& .MuiButton-root, & .MuiSvgIcon-root, & .MuiAvatar-root, & #mui-rte-toolbar, & .MuiAlert-icon":
      {
        display: "none",
      },
    "*": {
      border: "none !important",
      boxShadow: "none !important",
      color: "black !important",
    },
    [`&.${classes.paper}`]: {
      marginTop: spacing(8),
    },
    "& .MuiAlert-standard": {
      padding: 0,
    },
  },
}));
