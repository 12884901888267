import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useTheme } from "@mui/material/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Tooltip, Typography } from "@mui/material";
import { format } from "date-fns";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";

import { usePessoa } from "../Pessoa/PessoaContext";
import { usePessoaDocumento } from "../PessoaDocumento/PessoaDocumentoContext";
import StyledRoot, { classes } from "./styles";
import { useHistoricoAtendimento } from "../PessoaAtendimento/useHistoricoAtendimento";
import { HistoricoItem } from "./HistoricoItem";
import { LoadingButton } from "../../components/Loading";

export const HistoricoAtendimento = () => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { historico, isLoading, hasMore, loadMore } = useHistoricoAtendimento();
  const { pessoa } = usePessoa();
  const { avatar, showDocumento } = usePessoaDocumento();

  if (!pessoa.id || historico.length === 0) return null;

  return (
    <StyledRoot>
      <Drawer
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !open && classes.drawerPaperClose,
            !isSmall && classes.positionRelative,
            "no-print"
          ),
        }}
        variant={!isSmall ? "permanent" : undefined}
        anchor="right"
        open={open}
      >
        <div className={classes.container}>
          <div className={classes.header}>
            <IconButton onClick={() => setOpen(false)}>
              <ChevronRightIcon />
            </IconButton>
          </div>
          <Avatar
            alt="Foto paciente"
            className={classes.avatar}
            src={showDocumento(avatar?.documento[0])}
          >
            {pessoa.nome.toUpperCase().substr(0, 2)}
          </Avatar>
          <Typography
            sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
            variant="h6"
          >
            {pessoa.nome}
          </Typography>
          {pessoa.nascimento && (
            <Typography variant="subtitle2">
              {format(new Date(pessoa.nascimento), "dd/MM/yyyy")}
            </Typography>
          )}
          <Typography variant="body2">{pessoa.RG}</Typography>
          <Typography variant="body2">
            {pessoa.numeroSusReabilitando}
          </Typography>
          <Typography>{pessoa.cid}</Typography>

          <List className={classes.historicoList}>
            {historico.map((item, index) => (
              <HistoricoItem key={index} item={item} />
            ))}
            {hasMore && (
              <ListItem>
                <LoadingButton
                  onClick={loadMore}
                  variant="text"
                  color="primary"
                  loading={isLoading}
                >
                  Carregar mais ...
                </LoadingButton>
              </ListItem>
            )}
          </List>
        </div>
      </Drawer>

      <Tooltip title="Histórico de atendimentos">
        {isSmall ? (
          <IconButton
            color="primary"
            className={classes.buttonHistorico + " no-print"}
            onClick={() => setOpen(true)}
            loading={isLoading}
          >
            <ManageSearchIcon />
          </IconButton>
        ) : (
          <Button
            color="primary"
            className={classes.buttonHistorico + " no-print"}
            endIcon={<ManageSearchIcon />}
            onClick={() => setOpen(true)}
          >
            Histórico
          </Button>
        )}
      </Tooltip>
    </StyledRoot>
  );
};
