import Grid from "@mui/material/Grid";
import { PessoaAssistenciaSocialDto } from "../dtos/PessoaAssistenciaSocial.dto";
import { SectionCreator } from "./FildCreator";

export const AssistenciaSocial = ({ pessoa, setPessoa }) => {
  if (!pessoa || !setPessoa) return null;

  const newPessoa = {
    ...pessoa,
    assistenciaSocial: {
      ...PessoaAssistenciaSocialDto,
      ...pessoa.assistenciaSocial,
    },
  };

  const setAssistenciaSocial = (value, section, prop) =>
    setPessoa({
      ...newPessoa,
      assistenciaSocial: {
        ...newPessoa.assistenciaSocial,
        [section]: {
          ...newPessoa.assistenciaSocial[section],
          [prop]: value,
        },
      },
    });

  return (
    <Grid container spacing={2} mb={2}>
      <SectionCreator
        title="Identificação"
        sectionObject={newPessoa.assistenciaSocial.identificacao}
        onChange={(prop, value) =>
          setAssistenciaSocial(value, "identificacao", prop)
        }
        mapperObject={mapper.identificacao}
        pessoaId={pessoa.id}
      />

      <SectionCreator
        title="Antecedentes Familiares"
        sectionObject={newPessoa.assistenciaSocial.antecedentes}
        onChange={(prop, value) =>
          setAssistenciaSocial(value, "antecedentes", prop)
        }
        mapperObject={mapper.antecedentes}
        pessoaId={pessoa.id}
      />

      <SectionCreator
        title="Situação de Saúde"
        sectionObject={newPessoa.assistenciaSocial.saude}
        onChange={(prop, value) => setAssistenciaSocial(value, "saude", prop)}
        mapperObject={mapper.saude}
        types={mapperTypes.saude}
        pessoaId={pessoa.id}
      />

      <SectionCreator
        title="Doenças Comuns da Infância"
        sectionObject={newPessoa.assistenciaSocial.doencas}
        onChange={(prop, value) => setAssistenciaSocial(value, "doencas", prop)}
        mapperObject={mapper.doencas}
        types={mapperTypes.doencas}
        pessoaId={pessoa.id}
      />

      <SectionCreator
        title="Relacionamento Familiar"
        sectionObject={newPessoa.assistenciaSocial.relacionamento}
        onChange={(prop, value) =>
          setAssistenciaSocial(value, "relacionamento", prop)
        }
        mapperObject={mapper.relacionamento}
        pessoaId={pessoa.id}
      />
    </Grid>
  );
};

const mapper = {
  identificacao: [
    "Natural de",
    "Cartório",
    "Registro nº",
    "Folha",
    "Livro",
    "Escolaridade",
    "Religião",
    "Matrícula ACEC",
    "Problemas apresentados",
    "Procedência do caso",
    "Tratamento anterior",
    "Transporte para vir a entidade",
    "Necessita acompanhante",
    "Responsáveis",
  ],
  pais: [],
  irmaos: [],
  antecedentes: [
    "Pessoa nervosa na família",
    "Como se manifesta",
    "Problemas de tóxico",
    "Deficientes na família",
    "Alcoolismo",
    "Outras doenças",
  ],
  saude: [
    "Concepção desejada",
    "Tentativa de aborto",
    "Pais tinham vício",
    "Boa saúde na concepção",
    "Realizado pré-natal",
    "Concluído",
    "Nascimento criança",
    "Parto hospitalar",
    "Assistido por",
    "Assistência médica pós nascimento",
    "Dá continuidade",
    "Vacinação",
  ],
  doencas: [
    "Doenças",
    "Outras",
    "Idade que andou",
    "Idade que falou",
    "Dorme bem",
    "Alimenta-se bem",
    "Enurese noturna",
  ],
  financeiro: [],
  convenios: [],
  relacionamento: [
    "Entre os pais",
    "Por que",
    "Entre a mãe e o assistido",
    "Por que",
    "Entre o pai e o assistido",
    "Por que",
    "Entre os irmãos",
    "Por que",
  ],
  autoridade: [],
};

const mapperTypes = {
  saude: {
    nasceuDe: ["Parto normal", "Cesariana", "Fórceps"],
  },
  doencas: {
    doencas: [
      "Sarampo",
      "Catapora",
      "Coqueluche",
      "Caxumba",
      "Rubéola",
      "Varíola",
    ],
  },
};
