import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

const laudoReceitaSetor = [5, 6, 7, 12, 14];

export const isSetorMedico = (setor) => {
  return laudoReceitaSetor.includes(setor?.id);
};

export const printTypes = [
  { label: "Atestado", value: "atestado" },
  { label: "Laudo médico", value: "laudo_medico" },
  { label: "Receita médica", value: "receita_medica" },
];

export const LaudoReceita = ({
  agendamento,
  laudo,
  receita,
  onLaudoChange,
  onReceitaChange,
  printType,
  onAtestadoChange,
  atestado,
}) => {
  if (!isSetorMedico(agendamento.setorAtendimento)) return false;

  const getClassName = (type) => (printType === type ? undefined : "no-print");

  return (
    <>
      <Grid item xs={12} className={getClassName("laudo_medico")}>
        <Typography fontWeight="bold">Laudo Médico</Typography>
        <TextField
          fullWidth
          onChange={(e) => onLaudoChange(e.target.value)}
          value={laudo}
          placeholder="Detalhamento do laudo médico"
          multiline
          rows={4}
        />
      </Grid>
      <Grid item xs={12} className={getClassName("receita_medica")}>
        <Typography fontWeight="bold">Receita Médica</Typography>
        <TextField
          fullWidth
          onChange={(e) => onReceitaChange(e.target.value)}
          value={receita}
          placeholder="Detalhamento do receita médica"
          multiline
          rows={4}
        />
      </Grid>
      <Grid
        item
        xs={12}
        className={printType === "atestado" ? undefined : "no-print"}
        mb={2}
      >
        <Typography fontWeight="bold">Atestado</Typography>
        <TextField
          fullWidth
          onChange={(e) => onAtestadoChange(e.target.value)}
          value={atestado}
          placeholder="Atestado"
          multiline
          rows={4}
        />
      </Grid>
    </>
  );
};
