import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import { format, parse } from "date-fns";
import { usePessoa } from "./PessoaContext";
import { PessoaBusca } from "../PessoaBusca/PessoaBusca";

export const PessoaEmAtendimento = () => {
  const { pessoa } = usePessoa();
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  console.log(pessoa);

  if (!pessoa.id)
    return (
      <>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
          <PessoaBusca onSelect={handleClose} onCancel={handleClose} />
        </Dialog>
        <Grid item xs={12}>
          <Alert severity="warning">
            Nenhum paciente selecionado,{" "}
            <Link href="#" onClick={() => setOpen(true)}>
              clique aqui
            </Link>{" "}
            para selecionar um
          </Alert>
        </Grid>
      </>
    );

  return (
    <>
      <Grid item xs={12} sm={2}>
        <TextField
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          fullWidth
          value={pessoa.id}
          label="Pront."
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          fullWidth
          value={pessoa.nome}
          label="Nome"
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          fullWidth
          value={
            pessoa.nascimento
              ? format(
                  parse(pessoa.nascimento, "yyyy-MM-dd", new Date()),
                  "dd/MM/yyyy"
                )
              : ""
          }
          label="Nascimento"
        />
      </Grid>
    </>
  );
};
