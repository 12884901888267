import React, { useState } from "react";
import {
  ListItem,
  ListItemText,
  Box,
  Collapse,
  Typography,
  Divider,
  Tooltip,
  Button,
} from "@mui/material";
import { format } from "date-fns";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import { ptBR } from "date-fns/locale";
import { useAuth } from "../Auth/AuthContext";

export const HistoricoItem = ({ item }) => {
  const [expanded, setExpanded] = useState(false);
  const { remember } = useAuth();

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <ListItem
        button
        onClick={toggleExpand}
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Tooltip title={item.profissional.nome}>
            <ListItemText
              primary={format(new Date(item.dataAtendimento), "dd 'de' MMMM", {
                locale: ptBR,
              })}
              secondary={item.setorAtendimento.nome}
            />
          </Tooltip>
        </Box>
        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box mx={2}>
          {item.descricao && (
            <Typography
              dangerouslySetInnerHTML={{ __html: item.descricao }}
              variant="body2"
              gutterBottom
              sx={{
                overflow: "auto",
                textAlign: "left",
                whiteSpace: "break-spaces",
              }}
            />
          )}
          <Button
            fullWidth
            component={Link}
            to={`/atendimento/${item.id}`}
            variant="text"
            color="primary"
            endIcon={<VisibilityIcon />}
            target={remember ? "_blank" : "_self"}
          >
            Ver atendimento
          </Button>
        </Box>
      </Collapse>
      <Divider />
    </>
  );
};
